import React, { useState, useRef, useEffect } from 'react';
import { FaHeart, FaEdit, FaSave, FaTrashAlt, FaTimes } from 'react-icons/fa';
import axios from 'axios';

const Modal = ({ selectedItem, onClose, refreshSidebar, userId, onRefreshComplete }) => {
  const [combinedItems, setCombinedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaved, setIsSaved] = useState(selectedItem?.isSaved || false);
  const [error, setError] = useState(null);
  const [noteContent, setNoteContent] = useState({
    title: selectedItem?.title || '',
    content: selectedItem?.content || ''
  });
  const titleRef = useRef(null);
  const contentRef = useRef(null);

  const handleRefreshComplete = () => {
    if (typeof onRefreshComplete === 'function') {
      onRefreshComplete();
    }
  };

  useEffect(() => {
    setNoteContent({
      title: selectedItem?.title || '',
      content: selectedItem?.content || ''
    });
  }, [selectedItem]);

  useEffect(() => {
    const checkSavedStatus = async () => {
      if (userId && selectedItem?.id && selectedItem.type === 'search') {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/search/saved/status`, {
              params: {
                response_id: selectedItem.id,
                user_id: userId
              }
            }
          );
          setIsSaved(!!response.data.is_saved);
        } catch (error) {
          console.error('Error checking saved status:', error);
        }
      }
    };

    checkSavedStatus();
  }, [userId, selectedItem]);

  const formatDate = (date) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return parsedDate.toLocaleString(navigator.language || 'en-US', options);
  };

  const handleSaveEdit = async () => {
    try {
      const newTitle = titleRef.current.textContent.trim();
      const newContent = contentRef.current.textContent.trim();

      if (!newTitle || !newContent) {
        setError('Title and content cannot be empty');
        return;
      }

      const updatedNote = {
        title: newTitle,
        content: newContent,
        updatedAt: new Date().toISOString(),
      };

      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/notes/${selectedItem.id}`, updatedNote);
      
      setNoteContent({
        title: newTitle,
        content: newContent
      });
      
      setIsEditing(false);
      setError(null);
      refreshSidebar();
    } catch (error) {
      console.error('Error updating note:', error);
      setError('Error updating the note');
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/notes/${selectedItem.id}`);
      refreshSidebar();
      onClose();
    } catch (error) {
      setError('Error deleting the note');
    }
  };

  const fetchSidebarData = async () => {
    if (!userId) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sidebar?user_id=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCombinedItems(data.data);
      } else {
        setError('Failed to fetch sidebar data');
      }
    } catch (err) {
      setError('Error loading your activity data. Try again later.');
    } finally {
      setLoading(false);
      handleRefreshComplete();
    }
  };


  const handleSave = async () => {
    try {
      const responseId = selectedItem.id;
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/search/saved`, {
        response_id: responseId,
        is_saved: !isSaved,
        user_id: userId,
      });

      setIsSaved(!isSaved);
      fetchSidebarData();
    } catch (error) {
      setError('Login to be able to save.');
    }
  };


  const handleKeyDown = (e, type) => {
    if (e.key === 'Enter' && type === 'title') {
      e.preventDefault();
      contentRef.current?.focus();
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    if (titleRef.current && contentRef.current) {
      titleRef.current.textContent = noteContent.title;
      contentRef.current.textContent = noteContent.content;
    }
  };

  return (
    <div className="modal-overlay" onClick={() => !isEditing && onClose()}>
      <div 
        className={`modal ${selectedItem.type === 'search' ? 'search-modal' : 'note-modal'}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button 
          className="close-modal" 
          onClick={() => {
            if (isEditing) {
              handleCancelEdit();
            }
            onClose();
          }}
        >
          x
        </button>
        <div className="modal-content">
          {error && <p className="error-message">{error}</p>}
          
          {selectedItem.type === 'search' ? (
            <>
              <div className="modal-actions">
                <button 
                  onClick={handleSave} 
                  className="action-button"
        
                >
                  {isSaved ? (
                    <>
                      <FaHeart style={{ color: 'goldenrod' }} /> Saved
                    </>
                  ) : (
                    <>
                      <FaHeart /> Save
                    </>
                  )}
                </button>
              </div>
              {selectedItem.date && (
                <p className="notes-timestamp" style={{ fontSize: '0.85rem' }}>
                  Searched on {formatDate(selectedItem.date)}
                </p>
              )}
              <h2 className="modal-title">{selectedItem.query}</h2>
              <p>{selectedItem.response}</p>
            </>
          ) : (
            <>
              <div className="modal-actions">
                {isEditing ? (
                  <>
                    <button onClick={handleSaveEdit} className="action-button">
                      <FaSave /> Save
                    </button>
                    <button onClick={handleCancelEdit} className="action-button">
                      <FaTimes /> Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={() => setIsEditing(true)} className="action-button">
                      <FaEdit /> Edit
                    </button>
                    <button onClick={handleDelete} className="action-button">
                      <FaTrashAlt /> Delete
                    </button>
                  </>
                )}
              </div>
              {selectedItem.date && (
                <p className="notes-timestamp" style={{ fontSize: '0.85rem' }}>
                  Last edited on {formatDate(selectedItem.date)}
                </p>
              )}
              {isEditing ? (
                <div>
                  <h3
                    contentEditable
                    ref={titleRef}
                    onKeyDown={(e) => handleKeyDown(e, 'title')}
                    className="editable-title"
                  >
                    {noteContent.title}
                  </h3>
                  <p
                    contentEditable
                    ref={contentRef}
                    className="editable-content"
                  >
                    {noteContent.content}
                  </p>
                </div>
              ) : (
                <>
                  <h3 className="note-title">{noteContent.title}</h3>
                  <p>{noteContent.content}</p>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;