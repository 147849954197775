import React, { useState, useEffect } from 'react';
import { supabase } from '../auth/authclient';
import './Header.css';
import Sidebar from './Sidebar/Sidebar';
import LoginPopup from '../auth/Login';
import SignUpPopup from '../auth/Signup';
import { FaBars, FaTimes } from 'react-icons/fa';
import Uncle from '../assets/AskUncleMenu.png';
import AddNote from './Addnote';

const Header = () => {
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAddNoteOpen, setIsAddNoteOpen] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const authListener = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null);
    });

    const fetchUser = async () => {
      const { data } = await supabase.auth.getUser();
      if (data?.user) setUser(data.user);
    };

    fetchUser();
    return () => {};
  }, []);


  const toggleSidebar = () => {
    setIsMenuOpen(false);
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleLoginPopup = () => {
    setIsMenuOpen(false);
    setIsLoginOpen(!isLoginOpen);
  };

  const toggleSignUpPopup = () => {
    setIsMenuOpen(false);
    setIsSignUpOpen(!isSignUpOpen);
  };

  const openSignUp = () => {
    setIsMenuOpen(false);
    setIsLoginOpen(false);
    setIsSignUpOpen(true);
  };

  const openLogin = () => {
    setIsMenuOpen(false);
    setIsSignUpOpen(false);
    setIsLoginOpen(true);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAddNoteClick = () => {
    if (!user) {
      setIsLoginOpen(true);
    } else {
      setIsMenuOpen(false);
      setIsAddNoteOpen(true);
    }
  };

  const handleMyActivityClick = () => {
    if (!user) {
      setIsLoginOpen(true);
    } else {
      setIsMenuOpen(false);
      setIsSidebarOpen(true);
    }
  };

  const handleProfileClick = () => {
    setIsMenuOpen(false);
    setIsSidebarOpen(true);
  };

  const handleSignInClick = () => {
    setIsMenuOpen(false);
    setIsLoginOpen(true);
  };

  const handleAddNoteCancel = () => {
    setIsAddNoteOpen(false);
  };

  const handleAddNoteSave = (title, content) => {
    // Save note logic
    setNotes((prevNotes) => [...prevNotes, { title, content }]);
    setIsAddNoteOpen(false);
  };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      setUser(null);
    } else {
      console.error("Error signing out:", error.message);
    }
  };

  // Define the onRefreshComplete function
  const onRefreshComplete = () => {
    console.log('Sidebar data refreshed!');
    // Logic to refresh sidebar or any other component
  };

  return (
    <div>
      <header className="header">
        <button className="sign-in-btn" onClick={handleAddNoteClick}>Add note</button>
        <button className="sign-in-btn" onClick={handleMyActivityClick}>My activity</button>
        {user ? (
          <button className="sign-in-btn" onClick={handleSignOut}>Sign out</button>
        ) : (
          <button className="sign-in-btn" onClick={toggleLoginPopup}>Login</button>
        )}
        <FaBars className="hamburger-menu-icon" onClick={toggleMenu} />
        {isMenuOpen && (
          <div className="mobile-menu">
            <button className="close-menu-btn" onClick={toggleMenu}>
              <FaTimes className="close-icon" />
            </button>
            <img src={Uncle} alt="Uncle" className="menu-img" />
            <button className="mobile-menu-item" onClick={handleMyActivityClick}>My activity</button>
            <button className="mobile-menu-item" onClick={handleAddNoteClick}>Add note</button>
            {user ? (
              <button className="mobile-menu-item" onClick={handleSignOut}>Sign out</button>
            ) : (
              <button className="mobile-menu-item" onClick={handleSignInClick}>Login</button>
            )}
          </div>
        )}
      </header>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} activityData={activityData} />
      {isLoginOpen && <LoginPopup togglePopup={toggleLoginPopup} openSignUp={openSignUp} />}
      {isSignUpOpen && <SignUpPopup togglePopup={toggleSignUpPopup} openLogin={openLogin} />}
      {isAddNoteOpen && (
        <AddNote
          onCancel={handleAddNoteCancel}
          onSave={handleAddNoteSave}
          onRefreshComplete={onRefreshComplete} // Pass onRefreshComplete function
        />
      )}
    </div>
  );
};

export default Header;
