import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { supabase } from '../../auth/authclient';
import SidebarHeader from './SidebarHeader';
import SidebarContent from './SidebarContent';
import Modal from './Modal';
import ProfileEditModal from './ProfileEditModal';

const Sidebar = ({ isOpen, toggleSidebar, refreshSidebar, onRefreshComplete }) => {
  const [combinedItems, setCombinedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isProfileEditOpen, setIsProfileEditOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [username, setUsername] = useState(null);

  const fetchUsername = async () => {
    if (!userId) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`);
      if (response.ok) {
        const userData = await response.json();
        console.log('API Response:', userData);
        setUsername(userData.data.username || 'Guest'); 
      } else {
        setError('Failed to fetch username');
      }
    } catch {
      setError('Error loading username. Try again later.');
    }
  };



  const fetchSidebarData = async () => {
    if (!userId) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sidebar?user_id=${userId}`);
      if (response.ok) {
        const data = await response.json();
        setCombinedItems(data.data);
      } else {
        setError('Failed to fetch sidebar data');
      }
    } catch {
      setError('Error loading your activity data. Try again later.');
    } finally {
      setLoading(false);
      if (typeof onRefreshComplete === 'function') onRefreshComplete();
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUserId(user?.id || null);
      setEmail(user?.email || null);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchUsername();
      fetchSidebarData();
    }
  }, [userId, refreshSidebar]);

  return (
    <>
      {isOpen && <div className="overlay" onClick={toggleSidebar} />}
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <SidebarHeader
          username={username}
          onRefresh={fetchSidebarData}
          onProfileEditOpen={() => setIsProfileEditOpen(true)}
        />
        <SidebarContent
          combinedItems={combinedItems}
          loading={loading}
          error={error}
          onSelectItem={setSelectedItem}
        />
      </div>
      {isProfileEditOpen && (
        <ProfileEditModal
          username={username}
          email={email}
          onClose={() => setIsProfileEditOpen(false)} 
        />
      )}
      {selectedItem && (
        <Modal
          selectedItem={selectedItem}
          onClose={() => setSelectedItem(null)}
          refreshSidebar={fetchSidebarData}
          userId={userId}
          onRefreshComplete={onRefreshComplete}
        />
      )}
    </>
  );
};

export default Sidebar;