import React, { useState, useEffect } from 'react';
import { supabase } from '../auth/authclient';
import axios from 'axios';
import headerimg from '../assets/AskUncleLogo.png';
import Placeholder from "../assets/AskUncle-original.png";
import search from '../assets/search-icon.png';
import Popup from './Aboutpopup';
import { FaHeart } from 'react-icons/fa';
import LoginPopup from '../auth/Login';
import SignUpPopup from '../auth/Signup';
import SearchResults from './SearchResults';
import './SearchInput.css';

const SearchInput = () => {
  const [user, setUser] = useState(null);
  const [query, setQuery] = useState('');
  const [uncleResponse, setUncleResponse] = useState('');
  const [searchLinks, setSearchLinks] = useState([]);
  const [loadingUncle, setLoadingUncle] = useState(false);
  const [loadingLinks, setLoadingLinks] = useState(false);
  const [errorUncle, setErrorUncle] = useState('');
  const [errorLinks, setErrorLinks] = useState('');
  const [isImageVisible, setIsImageVisible] = useState(true);
  const [isResultDisplayed, setIsResultDisplayed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [responseId, setResponseId] = useState(null);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data, error } = await supabase.auth.getUser();
        if (error) {
          console.error('Error fetching user:', error);
        } else if (data) {
          setUser(data.user);
        }
      } catch (err) {
        console.error('Unexpected error fetching user:', err);
      }
    };

    fetchUser();
  }, []);

  const fetchUncleResponse = async () => {
    setLoadingUncle(true);
    setErrorUncle('');
    setUncleResponse('');
    setResponseId(null);

    try {
      const uncleRes = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/search/`, {
        query,
        user_id: user?.id,
      });

      if (uncleRes.data && uncleRes.data.status === 'success') {
        setUncleResponse(uncleRes.data.response);
        setResponseId(uncleRes.data.response_id);
      } else {
        throw new Error('Invalid Uncle response format');
      }
    } catch (err) {
      console.error('Error fetching Uncle response:', err);
      setErrorUncle(err.response?.data?.message || 'Failed to fetch Uncle’s response. Please try again.');
    } finally {
      setLoadingUncle(false);
    }
  };

  const fetchSearchLinks = async () => {
    setLoadingLinks(true);
    setErrorLinks('');
    setSearchLinks([]);

    try {
      const searchLinksRes = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/search/query`, {
        query,
      });

      if (searchLinksRes.data && searchLinksRes.data.items) {
        const parsedLinks = searchLinksRes.data.items.map((item) => ({
          name: item.name,
          url: item.url,
          snippet: item.snippet,
          displayUrl: item.displayUrl,
          datePublishedDisplayText: item.datePublishedDisplayText || '',
          openGraphImage: item.openGraphImage?.contentUrl || Placeholder,
        }));
        setSearchLinks(parsedLinks);
      } else {
        throw new Error('Invalid search links format');
      }
    } catch (err) {
      console.error('Error fetching search links:', err);
      setErrorLinks(err.response?.data?.message || 'Well this is embarrassing... I don’t have any links for you at the moment');
    } finally {
      setLoadingLinks(false);
    }
  };

  const handleSearch = async () => {
    if (!query.trim()) {
      setErrorUncle('Please enter a question');
      return;
    }

    setIsImageVisible(false);
    setIsResultDisplayed(true);
    setIsSaved(false);

    setLoadingUncle(true);
    setLoadingLinks(true);
    setErrorUncle('');
    setErrorLinks('');
    setUncleResponse('');
    setSearchLinks([]);

    try {
      // Fetch both Uncle's response and search links in parallel using Promise.all
      await Promise.all([fetchUncleResponse(), fetchSearchLinks()]);
    } catch (err) {
      console.error('Error fetching responses:', err);
      setErrorUncle(err.response?.data?.message || 'Failed to fetch Uncle’s response. Please try again.');
      setErrorLinks(err.response?.data?.message || 'Well this is embarrassing... I don’t have any links for you at the moment');
    } finally {
      setLoadingUncle(false);
      setLoadingLinks(false);
    }
  };

  const handleSave = async () => {
    if (user) {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/search/saved`, {
          response_id: responseId,
          is_saved: !isSaved,
          user_id: user.id,
        });
        setIsSaved(!isSaved);
      } catch (error) {
        console.error('Error saving response:', error);
        setErrorUncle('Failed to save response. Please try again.');
      }
    } else {
      setIsLoginOpen(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleLogoClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const toggleLoginPopup = () => {
    setIsLoginOpen(!isLoginOpen);
  };

  const toggleSignUpPopup = () => {
    setIsSignUpOpen(!isSignUpOpen);
  };

  const openSignUp = () => {
    setIsLoginOpen(false);
    setIsSignUpOpen(true);
  };

  const openLogin = () => {
    setIsSignUpOpen(false);
    setIsLoginOpen(true);
  };

  return (
    <div className="homepage">
      <div className={`search-container ${isResultDisplayed ? 'move-to-top' : ''}`}>
        <img
          src={headerimg}
          className={`headerimg ${isImageVisible ? '' : 'shrink'}`}
          alt=""
          onClick={handleLogoClick}
        />

        {showPopup && <Popup onClose={handleClosePopup} />}

        <div className="search-input-container">
          <input
            type="text"
            className="search-input"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ask me anything about boxing..."
            disabled={loadingUncle || loadingLinks}
          />
          <img
            src={search}
            className="search-icon"
            width="30px"
            alt="search icon"
            onClick={handleSearch}
          />
        </div>

        <div className="response-container">
          {errorUncle && <div className="error">{errorUncle}</div>}
          {loadingUncle ? (
            <div className="response-skeleton"></div>
          ) : (
            uncleResponse && (
              <div className="response">
                <p className="response-body">{uncleResponse}</p>
                <div className="save-container">
                  <button className="save-button" onClick={handleSave}>
                    {isSaved ? 'Saved' : 'Save'}
                    <FaHeart className="save-icon" />
                  </button>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {/* Render SearchResults with search links */}
      {errorLinks && <div className="error">{errorLinks}</div>}
      {loadingLinks && <div className="loading-spinner" style={{ marginTop: '20px' }}></div>}
      {searchLinks.length > 0 && <SearchResults results={searchLinks} />}

      {isLoginOpen && (
        <LoginPopup togglePopup={toggleLoginPopup} openSignUp={openSignUp} />
      )}
      {isSignUpOpen && (
        <SignUpPopup togglePopup={toggleSignUpPopup} openLogin={openLogin} />
      )}
    </div>
  );
};

export default SearchInput;
