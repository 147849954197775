// Home.js
import React from 'react';
import background from '../assets/background.jpg';
import SearchInput from '../components/SearchInput';
const Home = () => {
    return (
        <div 
            className="home"
            style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                height: '100vh',
                width: '100vw',
                overflow: 'auto',
            }}
        >
            <div>
                <SearchInput />
            </div>
        </div>
    );
};

export default Home;
