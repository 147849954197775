import React, { useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./SearchInput.css";
import "./SearchResults.css";
import Placeholder from "../assets/AskUncle-original.png";

const SearchResults = ({ results }) => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollWidth = 210;

  const scrollLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      if (carouselRef.current) {
        carouselRef.current.scrollBy({ left: -scrollWidth, behavior: "smooth" });
      }
    }
  };

  const scrollRight = () => {
    if (currentIndex < results.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      if (carouselRef.current) {
        carouselRef.current.scrollBy({ left: scrollWidth, behavior: "smooth" });
      }
    }
  };

  return (
    <div className="results-container-wrapper">
      <div className="carousel-controls left">
        <button
          className="carousel-button"
          onClick={scrollLeft}
          disabled={currentIndex === 0}
        >
          <FaChevronLeft size={16} className="chevron-icon" />
        </button>
      </div>

      <div className="results-container" ref={carouselRef}>
        {results.map((result, index) => {
          const openGraphImage = result.openGraphImage?.contentUrl || Placeholder;
          const name = result.name || "No title available";
          const url = result.url || "#";
          const snippet = result.snippet || "No description available";
          const displayUrl = result.displayUrl || "";
          const datePublishedDisplayText = result.datePublishedDisplayText || "";

          //console.log('Open Graph Image Object:', result.openGraphImage);
          //console.log('Resolved Open Graph Image URL:', openGraphImage);


          return (
            <a
              href={url}
              key={index}
              className="results-response"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="result-image-container">
                <img src={result.openGraphImage} onerror="this.onerror=null; this.src='https://cvdxukbpsspdmytodgek.supabase.co/storage/v1/object/sign/BoxingGenius%20-%20General/AskUncle.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJCb3hpbmdHZW5pdXMgLSBHZW5lcmFsL0Fza1VuY2xlLnBuZyIsImlhdCI6MTczMjM5NTM3MiwiZXhwIjoxODkwMDc1MzcyfQ.-DPn1x3XuUCB2YdgPznWzaBRriEQEZV6LzSgz9cowms&t=2024-11-23T20%3A56%3A12.876Z';" className="result-image" />
              </div>
              <div className="results-card">
                <p className="result-title">{name}</p>
                <p className="result-date">{datePublishedDisplayText}</p>
                <p className="result-url">{displayUrl}</p>
                <p className="result-snippet">{snippet}</p>
              </div>
            </a>
          );
        })}
      </div>

      <div className="carousel-controls right">
        <button
          className="carousel-button"
          onClick={scrollRight}
          disabled={currentIndex === results.length - 1}
        >
          <FaChevronRight size={16} className="chevron-icon" />
        </button>
      </div>
    </div>
  );
};

export default SearchResults;
