import React from 'react';
import { FaSyncAlt } from 'react-icons/fa';

const SidebarHeader = ({ username, onRefresh, onProfileEditOpen }) => {
  const truncatedUsername = username?.length > 10 ? `${username.slice(0, 10)}...` : username;

  return (
    <div className="sidebar-header">
      <span className="greeting">
        {`Hi ${truncatedUsername || ''}`}
      </span>
      <a
        href="#"
        className="edit-profile-link"
        onClick={(e) => {
          e.preventDefault();
          onProfileEditOpen();
        }}
      >
        Edit Profile
      </a>
      <button className="refresh-button" onClick={onRefresh}>
        <FaSyncAlt size={15} />
      </button>
    </div>
  );
};

export default SidebarHeader;
