import React, { useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { supabase } from '../../auth/authclient';
import './Sidebar.css';

const ProfileEditModal = ({ onClose, username, email }) => {
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [newUsername, setNewUsername] = useState(username || 'Your username');
  const [newEmail, setNewEmail] = useState(email || 'you@guest.com');
  const [newPassword, setNewPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const saveUsername = async () => {
    setIsLoading(true); // Start loading
    try {
      if (newUsername === username) {
        setErrorMessage('Username is unchanged. Please enter a new username.');
        setSuccessMessage(null);
        return;
      }

      const user = (await supabase.auth.getUser()).data.user;
      if (!user) {
        throw new Error('User not authenticated');
      }

      const userId = user.id;

      console.log(userId)

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({
          username: newUsername,
          user_id: user.id,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update username');
      }

      const result = await response.json();
      setIsEditingUsername(false);
      setSuccessMessage('Username updated successfully!');
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(`Error updating username: ${error.message}`);
      setSuccessMessage(null);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const saveEmail = async () => {
    setIsLoading(true); // Start loading
    try {
      if (newEmail === email) {
        setErrorMessage('Email is unchanged. Please enter a new email address.');
        setSuccessMessage(null);
        return;
      }

      const { data, error } = await supabase.auth.updateUser({ email: newEmail });

      if (error) {
        throw new Error(error.message);
      }

      if (data) {
        setIsEditingEmail(false);
        setSuccessMessage('Check your inbox to confirm the email change.');
        setErrorMessage(null);
      }
    } catch (error) {
      setErrorMessage(`Error updating email: ${error.message}`);
      setSuccessMessage(null);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const savePassword = async () => {
    setIsLoading(true); // Start loading
    try {
      if (!newPassword) {
        setErrorMessage('Password cannot be empty.');
        setSuccessMessage(null);
        return;
      }

      const { data, error } = await supabase.auth.updateUser({ password: newPassword });

      if (error) {
        throw new Error(error.message);
      }

      if (data) {
        setIsEditingPassword(false);
        setSuccessMessage('Password updated successfully!');
        setErrorMessage(null);
      }
    } catch (error) {
      setErrorMessage(`Error updating password: ${error.message}`);
      setSuccessMessage(null);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="search-modal edit-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-modal" onClick={onClose}>
          x
        </button>
        <div className="modal-content">
          <h2>Edit Your Profile</h2>
          <table className="profile-edit-table">
            <tbody>
              {/* Username Section */}
              <tr>
                <td className="label-cell">
                  <label>Username:</label>
                </td>
                <td className="info-cell">
                  {isEditingUsername ? (
                    <input 
                      className='edit-user-details'
                      type="text"
                      value={newUsername}
                      onChange={(e) => setNewUsername(e.target.value)}
                    />
                  ) : (
                    <label>{newUsername}</label>
                  )}
                </td>
                <td className="button-cell">
                  {isEditingUsername ? (
                    <button className="action-button" onClick={saveUsername} disabled={isLoading}>
                      {isLoading ? <span className="loading-spinner"></span> : <FaSave />} Save
                    </button>
                  ) : (
                    <button className="action-button" onClick={() => setIsEditingUsername(true)}>
                      Edit
                    </button>
                  )}
                </td>
              </tr>

              {/* Email Section */}
              <tr>
                <td className="label-cell">
                  <label>Email:</label>
                </td>
                <td className="info-cell">
                  {isEditingEmail ? (
                    <input 
                      className='edit-user-details'
                      type="email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                  ) : (
                    <label>{newEmail}</label>
                  )}
                </td>
                <td className="button-cell">
                  {isEditingEmail ? (
                    <button className="action-button" onClick={saveEmail} disabled={isLoading}>
                      {isLoading ? <span className="loading-spinner"></span> : <FaSave />} Save
                    </button>
                  ) : (
                    <button className="action-button" onClick={() => setIsEditingEmail(true)}>
                      Edit
                    </button>
                  )}
                </td>
              </tr>

              {/* Password Section */}
              <tr>
                <td className="label-cell">
                  <label>Password:</label>
                </td>
                <td className="info-cell">
                  {isEditingPassword ? (
                    <input 
                      className='edit-user-details'
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Enter new password"
                    />
                  ) : (
                    <label>**********</label>
                  )}
                </td>
                <td className="button-cell">
                  {isEditingPassword ? (
                    <button className="action-button" onClick={savePassword} disabled={isLoading}>
                      {isLoading ? <span className="loading-spinner"></span> : <FaSave />} Save
                    </button>
                  ) : (
                    <button className="action-button" onClick={() => setIsEditingPassword(true)}>
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          {/* Success and Error Messages */}
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          {/* Terms and Privacy Links */}
          <div className='terms' style={{ marginTop: '2rem', textAlign: 'center' }}>
            <a href="#" className="terms-link">Terms of Use</a>
            &nbsp;|&nbsp;
            <a href="#" className="terms-link">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEditModal;
