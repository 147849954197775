import React from 'react';
import { FaHeart, FaEdit } from 'react-icons/fa';

const SidebarContent = ({ combinedItems, loading, error, onSelectItem }) => {
  const formatDate = (date) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    return parsedDate.toLocaleString(navigator.language || 'en-US', options);
  };

  const limitText = (text) => (text?.length > 25 ? `${text.substring(0, 25)}...` : text);

  return (
    <div className="history-notes-section">
      {loading && <p className="loading-spinner"></p>}
      {error && <p className="error-message">{error}</p>}
      {!loading && combinedItems.length === 0 && <p>No items to display</p>}
      {combinedItems.map((item, index) => (
        <div
          key={index}
          className={item.type === 'search' ? 'search-card' : 'note-card'}
          onClick={() => onSelectItem(item)}
        >
          {item.type === 'search' ? (
            <>
              <h3 className="search-title">{limitText(item.query)}</h3>
              <p>{limitText(item.response)}</p>
              <p className="timestamp">{formatDate(item.date)}</p>
              <FaHeart className="icon-bottom-right" />
            </>
          ) : (
            <>
              <h3 className="note-title">{item.title}</h3>
              <p>{limitText(item.content)}</p>
              <p className="timestamp">{formatDate(item.date)}</p>
              <FaEdit className="icon-bottom-right" />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default SidebarContent;
